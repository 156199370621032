// .row-eq-height > [class^='col']:first-of-type .chat {
//   flex-grow: 1;
// }

@include media-breakpoint-down(md) {
  .chat {
    min-height: 50vh;
  }
}

@mixin message($bgcolor, $color) {
  display: inline-block;
  vertical-align: top;
  max-width: 80%;

  .name {
    font-weight: bold;
  }

  .content {
    background: $bgcolor none repeat scroll 0 0;
    border-radius: 3px;
    color: $color;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
  }
}

#wrapper {
  display: flex;
  flex-direction: row;
}

@include media-breakpoint-down(md) {
  #wrapper {
    flex-direction: column;
  }
}

#player {
  min-height: 100px;
  flex: 2;
}

#chat {
  position: relative;
  flex: 1;
}

@include media-breakpoint-down(md) {
  .chat {
    min-height: 350px;
  }
}

@include media-breakpoint-up(lg) {
  #chat {
    max-width: 500px;
  }
}

#chat-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 38px;
  overflow: auto;
}

.name-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0px;
  overflow: auto;

  width: 100%;
  // height: 100%;

  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;

  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.notconnected-overlay {
  @extend .name-overlay;

  align-items: center;
  color: #e72424;
}

.chat {
  .messages {
    .message {
      overflow: hidden;
      margin: 10px 0 10px;
    }

    .message:first-of-type {
      margin-top: 0px !important;
    }

    .message:last-of-type {
      margin-bottom: 0px !important;
    }

    .incoming {
      @include message(#ffffff, #646464);
    }

    .outgoing {
      @include message(#4eb7ca, #fff);

      float: right;
    }

    p {
      margin-bottom: 0;
    }
  }

  .messages::-webkit-scrollbar {
    display: none;
  }

  .input-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .emoji-picker {
    position: absolute;
    right: 0;
    top: -320px;

    .emoji-picker-react {
      box-shadow: 0 0 #000;
    }
  }
}
