// // Theme colors
$primary: #1b2b48;
// $success: #00c85a;
// $danger: #e72424;
$warning: #ee7c00;
$info: #4eb7ca;

// // React Toastify colors
// $rt-color-info: $info;
// $rt-color-success: $success;
// $rt-color-warning: $warning;
// $rt-color-error: $danger;
