.plyr {
  max-height: 100vh;
}

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded='true'] {
  background: #ee7c00 !important;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded='true'] {
  background: #ee7c00 !important;
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  background: #ee7c00 !important;
}

.plyr__menu__container
  .plyr__control[role='menuitemradio'][aria-checked='true']::before {
  background: #ee7c00 !important;
}

.plyr--full-ui input[type='range'] {
  color: #ee7c00 !important;
}

.plyr__control--overlaid {
  background: rgba(238, 124, 0, 0.8) !important;
}

.plyr--full-ui
  input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(238, 124, 0, 0.5) !important;
}

.plyr--full-ui input[type='range'].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(238, 124, 0, 0.5) !important;
}

.plyr--full-ui input[type='range'].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(238, 124, 0, 0.5) !important;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(238, 124, 0, 0.5) !important;
}
