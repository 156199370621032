@import './quizzzit-player';
@import './chat';

body {
  background: url('/static/images/background.png') no-repeat center center,
    #1a2a47;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}
