body,
html {
  margin: 0 0 0 0px;
  height: 100%;
}

#root {
  height: 100%;
  max-height: 100vh;
}

.container {
  height: 100%;
  max-height: 100vh;
}

.embed {
  height: 100vh;

  .plyr {
    max-height: 100% !important;
  }
}
